import React from "react"
import Layout from "../../components/layout"
import logo from "../../images/candide_logo.png"
import ship from "../../images/candide_schiff.jpg"

const Subscription = () => (
  
  <Layout title="Email bestätigen">
    <div className="min-h-screen px-6 py-20 max-w-2xl mx-auto">
        <img src={ship} alt="Candide Reise" className="max-w-2xl mx-auto" />
        <h1 className="text-5xl md:text-6xl font-display leading-none">Woohoo, du kommst mit auf diese Reise!</h1>
        <p className="mt-10 text-xl font-display">
            Jetzt bitte noch schnell in deiner Inbox 
            <span className="ml-1 border-b border-canred ">die Email bestätigen</span>,
            dann segeln die Rezepte direkt zu dir.</p>
        <p className="mt-8 text-xl font-display">Wir freuen uns auf dein Feedback!</p>
        <p className="text-xl font-display">Candide Schokoladen</p>
        <img src={logo} alt="Candide Schokoladen" className="mt-20 w-56 mx-auto" />
    </div>
  </Layout>
)

export default Subscription


